import { createRoot } from 'react-dom/client'
import './styles.css'
import App from './App'
import { Logo } from '@pmndrs/branding'

function Overlay() {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }}>
      <div style={{ position: 'absolute', top: 40, left: 40 }}></div>
      <div style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px' }}>spekover</div>
    </div>
  )
}

createRoot(document.getElementById('root')).render(
  <>
    <App
      spheres={[
        [1, '#A64B4B', 0.05, [-4, -1, -1]],
        [0.75, '#F54F29', 0.1, [-4, 2, -2]],
        [1.25, '#A64B4B', 0.2, [4, -3, 2]],
        [1.5, '#A64B4B', 0.3, [-4, -2, -3]],
        [2, '#F54F29', 0.3, [-4, 2, -4]],
        [2, '#F4C28D', 0.3, [-4, 2, -4]],
        [1.5, '#F4C28D', 0.05, [-4, -1, -1]],
        [2, '#FFD393', 0.1, [-4, 2, -2]],
        [1.5, '#9C9B7A', 0.2, [4, -3, 2]],
        [1.25, '#D99A4E', 0.3, [-4, -2, -3]],
        [1, '#405952', 0.3, [-4, 2, -4]],
        [1, '#D99A4E', 0.3, [-4, 2, -4]]
      ]}
    />
    <Overlay />
  </>
)
